<template>
  <div class="container flex-1 py-8 bg-white lg:pb-20 lg:pt-10 lg:px-10 max-w-7xl">
    <div class="prose-sm prose prose-indigo sm:max-w-none sm:prose">
      <h1>Disclaimer</h1>
      <p><strong>How we work, how we make money, risk warnings and disclaimers</strong></p>
      <p><em>Can you give me advice?</em></p>
      <p>
        Sorry, we can’t give you advice on which broker to use, when to invest, how much to invest or what to invest in.
        Please read our risk warning and disclaimer (below). If you are still unsure after using our site it would be a
        good idea to get independent advice from a qualified professional financial advisor.
      </p>
      <p><em>How do you make money?</em></p>
      <p>
        Our site is free for you to use. Instead of charging you to use our site, we make money from the brokers and
        business partners with whom we have commercial arrangements. This can happen in a number of ways; for example
        when you click a button and go through to a broker’s website, or when you open an account with that broker.
      </p>
      <p>
        Our broker lists and comparisons may not list or compare all relevant brokers, exchanges or investments and are
        by default ordered by reference to our commercial agreements unless otherwise stated. We may list products or
        brokers that we don’t make money from, and you can identify them because they will appear without a “button”, or
        in another colour.
      </p>
      <p>
        Brokers and other service providers may also pay us to advertise on our site, or to feature in our emails, know
        how guides and/or articles.
      </p>
      <p>By using our site, you agree to us receiving and retaining fees from our business partners.</p>
      <p><em>How do you use my data?</em></p>
      <p>Please see our data privacy policy.</p>
      <p><em>Would you like to know more?</em></p>
      <p>
        If you would like more information or have any questions, don’t hesitate to get in touch by email at
        info@wealth-info.com
      </p>
      <p><em>Risk Warning</em></p>
      <p>
        Investing is speculative and carries a high level of risk. Each investment is unique and involves unique risks.
      </p>
      <p>When Investing in<strong> stocks and other negotiable securities </strong>your capital is at risk.</p>
      <p>
        <strong>Stocks and Shares </strong>can fluctuate widely in price and are, therefore, not appropriate for all
        investors. Investing in stocks may not be supervised under your domestic regulatory framework. Your capital is
        at risk.
      </p>
      <p>
        <strong>Stocks &amp; Shares are </strong>instruments and come with a high risk of losing money rapidly due to
        leverage. You should consider whether you understand how an investment works and whether you can afford to take
        the high risk of losing your money. Investing may not be legal in your country of residence. Please check the
        legality of your chosen investment before investing.
      </p>
      <p>
        In all cases, past performance is not an indication of future results. Investing history presented may be less
        than 5 years old unless otherwise stated and may not suffice as a basis for investment decisions. Prices may go
        down as well as up, prices can fluctuate widely and you may lose all of or more than the amount you invest.
        Investing is not suitable for everyone, so please ensure that you have fully understood the risks and legalities
        involved. If you are unsure, seek independent financial, legal, tax and/or accounting advice. This website does
        not provide investment, financial, legal, tax or accounting advice.
      </p>
      <p>
        Be aware that your use of this website and/or its features, investing in a particular investment and/or entering
        into a relationship with a particular broker or exchange may not be compliant with the laws, regulations or
        other legal requirements of your country or state of residence. This site is not intended for use in
        jurisdictions in which the investments described are prohibited and should only be used by such persons and in
        such ways as are legally permitted.
      </p>
      <p>
        Not all brokers and exchanges referred to on this site will be regulated in your country or state of residence
        and your investments may not qualify for investor protection. Please conduct your own due diligence.
      </p>
      <p>
        We do not and cannot guarantee that your use of this website and/or any of its features will generate profits.
        All opinions, news, reviews, research, analysis, prices, or other information contained on or provided via this
        site (including in calls, emails, LiveChat, SMS or other forms of communication) are provided on an “as is”
        basis as general market commentary and/or expressions of opinion only. Information may not be complete, accurate
        or up to date and may not be suitable for every individual, nor be a suitable basis for an investment decision.
      </p>
      <p><strong>Disclaimer</strong></p>
      <p>
        Without prejudice to this website’s terms and conditions of use, by using this website you acknowledge and agree
        to the following.
      </p>
      <p>
        You use this website and the information provided on or through this website and/or its features (including
        information provided in calls, emails, LiveChat, SMS or other forms of communication) at your own risk. To the
        extent permitted by applicable law, this site, its owners, operators and affiliates and its and their respective
        employees and officers shall not be liable for any cost, loss, liability or expense that you or anyone else
        incurs as a result of using or relying on (or being unable to use or rely on) this website and/or any of its
        related features, entering into a relationship with any broker or exchange, or investing in any investment
        (including, for the avoidance of doubt, but without limitation, direct, indirect, consequential and/or economic
        losses).
      </p>
      <p>
        No representation, warranty, undertaking, assurance or guarantee (express or implied) is made or given as to the
        availability, adequacy, accuracy, completeness, reasonableness or appropriateness of any of the information or
        opinions provided or expressed on or through this site and/or its features (including in calls, emails,
        LiveChat, SMS or other forms of communication (if applicable). The content and features of this website may be
        corrected or modified at any time, without advance notice or reason. There is no obligation to notify you of any
        corrections or modifications.
      </p>
      <p>
        Please be aware that some of the links on this site may direct you to the websites of third parties, some of
        whom are marketing affiliates and/or business partners of this site and/or its owners, operators and affiliates.
        Notwithstanding any such relationship, no responsibility is accepted for the conduct of any third party nor the
        content or functionality of their websites or applications. A hyperlink to or positive reference to or review of
        a broker or exchange should not be understood to be an endorsement of that broker or exchange’s products or
        services. Investing with or through a broker or exchange will be subject to that broker’s or exchange’s terms of
        use or business and fees / commissions may be payable. Please ensure that you have read and understood the terms
        before investing.
      </p>
      <p>
        All content on this website belongs to wealth-info.com, unless stated otherwise. Whenever shared or adapted the
        content must be accompanied with appropriate credit to this site with links or hypertexts leading to this site.
        All third-party owned content appearing on this website is subject to the rights of their respective owners and
        applicable use and redistribution rules.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>
